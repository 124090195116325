.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
}
.cFooterWrapper>hr{
    width: 100%;
   background: white;
    border: none;
    height: 1px;
    
}
.cFooter{
    display: flex;
    width: 100%;
    justify-content: space-around;
    
}
.Logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 3rem;
}
.Logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.Logo>span{
    font-weight: 600;
    font-size: 1rem;
}

.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}
.detail{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: inherit;
}
.pngLine{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.detail>:nth-child(2){
    font-weight: 600;
    font-size: 1rem;
}
.copywrite{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin-top: 3rem;
}