.virtual{
    display: flex;
    margin-top: 6rem;
    margin-bottom: 6rem;
    padding: 2rem;
    justify-content: space-around;
}
.lft{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.lft>:nth-child(1)
{
    font-size: 2rem;
    font-weight: bold;

}
.lft>:nth-child(2)
{
    font-size: 1.6rem;
    width: 20rem;
    display: block;

}
.lft>:nth-child(3)
{
    font-size: 2rem;
    font-weight: bold;

}
.lft>img
{
  
    position: absolute;
    width: 10rem;
    bottom: 7rem;
   

}
.wra{
    width: 35rem;
    
}