.s-container{
    position: relative;
}
.s-container .swiper{
    width: 70%;
    position: unset;
    height: 12rem
}
.s-container .swiper-slide{
    display: flex;
    background-color: white;
    padding: 1rem;
    position: relative;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;

}
.s-container .swiper-button-prev{
    padding-left: 6rem;
    color: black;
}
.s-container .swiper-button-next{
    padding-right: 6rem;
    color: black;
}
.l-sidee{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.name{
    display: flex;
    flex-direction: column;
}
.name>:first-child{
    font-size: 1.5rem;
    font-weight: 800;
}
.name>:nth-child(2){
    font-weight: 1rem;
    padding-top: .2rem;
}
.l-sidee>:nth-child(2){
    font-weight: bold;
    font-size: 3rem;
}
.l-sidee>:nth-child(3){
    border-radius: 20px;
    font-size: .8rem;
    font-weight: 600;
    border: 1px solid var(--black);
    padding: 5px 10px;
    width: max-content;
}
.img-p{
    transform: rotate(-20deg);
    position: absolute;
    right: 0;
    width: 35%;
    height: 100%;
    bottom: -20%;
}
