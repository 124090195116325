.test{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
}
.wrap{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: flex-end;
    width: 100%;
}
.wrap>img{
    width: 30rem;
    justify-self: center;
}
.p2{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.p2>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
}
.p2>:nth-child(2){
    font-size: 1.5rem;
    font-weight: 400;
    display: block;
    text-transform: uppercase;
}
.wrap>:nth-child(3){
    text-align: right;
    justify-content: right;
}
.carousal{
    width: 100%;
    
}
.tCoursal{
    padding: 2rem;
    

}
.teste{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: white;
    box-shadow: 0rem 1rem 3rem -50px #7d7d7d;
    border-radius: 20px;
    padding: 2rem;
   
}