.container{
    
    padding: 0rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}
.wrapper{
    display: flex;
    align-items: baseline;
    position: relative;
    justify-content: center;
}
.wrapper>img{
    width: 25rem;
    bottom: 0;
    position: absolute;
}
.circle{
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    background: blueviolet;
    z-index: -99;
    position: absolute;
    bottom: 0;
}
.cart2{
    position: absolute;
    display: flex;
    bottom: 20%;
    right: 9%;
}
.cart2>svg{
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border:6px solid black;
}
.signup{
    margin-left: 1rem;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    font-size: 1rem;
    padding: 1rem;
    box-shadow: var(--shadow1);
    display: flex;

}
.signup>:first-child{
    font-weight: 600;
}
.signup>:last-child{
    margin-left: 1rem;
    width: 20px;
    height: 20px;

    text-align: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid black;
}





.h-side{
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.text1{
    font-weight: 600;
    display: block;
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    padding-top: 2rem;
    
}
.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
    padding-top: 7rem;
    
}
.text2>:first-child{
    font-weight: 800;
    font-size: 2rem;
}
.text2>:last-child{
    display: block;
}


.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.traffic>:first-child{
    font-weight: 800;
    font-size: 3rem;
}
.customer{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.customer>:first-child{
    font-weight: 800;
    font-size: 3rem;
}