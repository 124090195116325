.containere{
    display: flex;
    padding: 1.5rem;
    
}
.logo{
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem;
}
.logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.logo>span{
    font-weight: 600;
    
}


.right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.menu{
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;
}
.menu>li:hover{
    cursor: pointer;
    color: lightpink;
    transition: .2s ease-in-out;
}
.search{
    border-radius: 20px;
    border: none;
    outline: none;
    padding: .7rem;
}