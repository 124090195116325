.containerr{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 0 2rem;
    position: relative;
}
.containerr>:nth-child(1){
    position: absolute;
    width: 8rem;
    left: 30%;
    bottom: 30rem;

}
.productss{
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
}
.men{
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 2rem;
    font-weight: 600;
    font-size: 1.4rem;
}
.men>li:hover{
    color: var(--pink);
    cursor: pointer;
}
.list{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(200px ,1fr));
    height: 25rem;
    justify-content: space-between;
    grid-gap: 2rem;
    overflow: scroll;
}
.pro{
    width: 12rem;
    height: 9rem;
    background-color: white;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    border-radius: 20px;

}
.pro>img{
    top: 3rem;
    width: 6rem;
    height: 11rem;
}